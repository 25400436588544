import React, { FC, useCallback, useState } from 'react';
import { Box, IconButton, List, Tooltip, Typography } from '@mui/material';
import MentionComponent from '../MentionComponent/MentionComponent';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MentionComponentProps } from '../MentionComponent/MentionTypes';
import { Comment, CommentsList as CommentsCollection, RootState, IdentityInfo, Referral } from '../../redux/types';
import { formatComment, sortByDate, userFriendlyTimestamp } from '../../globalUtils/utils';
import {
  CommentActions,
  CommentsActionProps,
  FollowUpCommentActionChip,
  PatientContactedCommentActionChip,
} from '../CommentsViewComponent/CommentsViewComponent';
import cloudUpload from '../../images/cloudUpload.svg';
import cloudUploadError from '../../images/cloudUploadError.svg';
import cloudUploadSuccess from '../../images/cloudUploadSuccess.svg';
import cloudOff from '../../images/cloudOff.svg';
import cloudUploadPrimary from '../../images/cloudUploadPrimary.svg';
import CommentActionMenu from './CommentActionMenu';
import { useSelector } from 'react-redux';
import { differenceInMinutes } from 'date-fns';
import { CommentTypes, UPLOAD_STATUSES } from '../../globalUtils/constants';
import EditComment from './EditComment';
import { GenericCommentComponent } from '../CommentsViewComponent/GenericCommentComponent';

interface Props extends MentionComponentProps {
  comments?: CommentsCollection;
  children?: any;
  hideSecondaryActions?: boolean;
  commentActionProps: CommentsActionProps;
  hideCommentEditing: boolean;
  referral: Referral;
}

const CommentsList: FC<Props> = (props) => {
  const {
    onSend,
    loading,
    id,
    comments,
    templates,
    children,
    isExpanded,
    commentActionProps,
    publishToEMRSelected,
    onSelectPublishToEMR,
    hidePublishToEMR,
    hideCommentEditing,
    disablePublishToEMR,
    referral,
  } = props;
  const sortedComments = comments?.data.sort((a, b) => sortByDate(a.createdAt, b.createdAt));
  const [moreMenuAnchorElement, setMoreMenuAnchorElement] = useState<null | HTMLElement>(null);
  const [selectedCommentForMoreActions, setSelectedCommentForMoreActions] = useState<Comment | undefined>();
  const {
    comments: {
      editCommentStore: { comment: commentBeingEdited },
    },
    auth: { identityInfo: loggedInUserInfo },
  } = useSelector((state: RootState) => state);

  const handleMoreIconClick = (event: React.MouseEvent<HTMLElement>, comment: Comment) => {
    setSelectedCommentForMoreActions(comment);
    setMoreMenuAnchorElement(event.currentTarget);
  };

  const checkIfSignedInUserIsOwnerOfComment = (comment: Comment, signedInUser: IdentityInfo): boolean => {
    return comment?.user?.email?.toLocaleLowerCase() === signedInUser?.preferred_username?.toLocaleLowerCase();
  };

  const checkIfCommentIsWithinEditDuration = (comment: Comment): boolean => {
    return differenceInMinutes(new Date(), new Date(comment.createdAt)) <= 10;
  };

  const checkIfCommentCanBeEdited = useCallback((comment: Comment, signedInUser: IdentityInfo): boolean => {
    const commentWithinEditDuration: boolean = checkIfCommentIsWithinEditDuration(comment);
    const loggedInUserIsOwnerOfComment: boolean = checkIfSignedInUserIsOwnerOfComment(comment, signedInUser);

    return commentWithinEditDuration && loggedInUserIsOwnerOfComment;
  }, []);

  const checkIfMoreIconShouldBeShown = useCallback(
    (comment: Comment, signedInUser: IdentityInfo): boolean => {
      const enableCommentEditing: boolean = !hideCommentEditing && checkIfCommentCanBeEdited(comment, signedInUser);
      return enableCommentEditing;
    },
    [checkIfCommentCanBeEdited, hideCommentEditing],
  );

  const onSubmit = useCallback(
    (value: string, mentions: any[]) => {
      onSend(value, mentions);
    },
    [onSend],
  );

  const handleGetCommentIconAndToolTipDefault = (
    comment: Comment,
    commentIsWithinEditDuration: boolean,
    referral: Referral,
  ): { icon: string; tooltipMessage: string } => {
    if (comment.type === CommentTypes.INTERNAL) {
      return {
        icon: cloudOff,
        tooltipMessage: commentIsWithinEditDuration
          ? 'Comment is editable for 10 minutes, then it will not be published to EMR'
          : 'Not published to EMR',
      };
    }

    if (referral?.patient?.isTemporary) {
      return {
        icon: cloudOff,
        tooltipMessage: commentIsWithinEditDuration
          ? 'Comment is editable for 10 minutes. It will be published to EMR once an EMR patient is selected'
          : 'Publishing to EMR paused until an EMR patient is selected',
      };
    }

    return {
      icon: commentIsWithinEditDuration ? cloudUpload : cloudOff,
      tooltipMessage: commentIsWithinEditDuration
        ? 'Comment is editable for 10 minutes, then it will be published to EMR'
        : 'Not published to EMR',
    };
  };

  const getCommentIconAndToolTip = (comment: Comment, referral: Referral): { icon: string; tooltipMessage: string } => {
    const commentIsWithinEditDuration: boolean = checkIfCommentIsWithinEditDuration(comment);

    switch (comment.uploadStatus) {
      case UPLOAD_STATUSES.IN_PROGRESS:
        return {
          icon: cloudUploadPrimary,
          tooltipMessage: 'Publishing to EMR in progress',
        };
      case UPLOAD_STATUSES.SUCCESS:
        return {
          icon: cloudUploadSuccess,
          tooltipMessage: 'Published to EMR',
        };
      case UPLOAD_STATUSES.FAILED:
        return {
          icon: cloudUploadError,
          tooltipMessage: comment.uploadStatusMessage || 'Failed Publishing to EMR',
        };
      default:
        return handleGetCommentIconAndToolTipDefault(comment, commentIsWithinEditDuration, referral);
    }
  };

  return (
    <Box style={{ minHeight: 300 }}>
      <Box style={{ marginBottom: 16 }} display="flex" justifyContent="space-between">
        <Typography variant="h6">Comments</Typography>
      </Box>
      <Box>
        <CommentActions isTopLevel {...commentActionProps} />
      </Box>
      <Box>
        <MentionComponent
          placeholder="Type Comment..."
          id={id}
          templates={templates}
          onSend={onSubmit}
          loading={loading}
          children={children}
          isExpanded={isExpanded}
          publishToEMRSelected={publishToEMRSelected}
          onSelectPublishToEMR={onSelectPublishToEMR}
          hidePublishToEMR={hidePublishToEMR}
          disablePublishToEMR={disablePublishToEMR}
        />
      </Box>
      <CommentActionMenu
        open={Boolean(moreMenuAnchorElement)}
        anchorEl={moreMenuAnchorElement}
        onClose={() => setMoreMenuAnchorElement(null)}
        onEditButtonClick={() => {
          setMoreMenuAnchorElement(null);
        }}
        selectedComment={selectedCommentForMoreActions}
        hideCommentEditing={
          hideCommentEditing ||
          !selectedCommentForMoreActions ||
          !checkIfCommentCanBeEdited(selectedCommentForMoreActions, loggedInUserInfo)
        }
      />
      <Box>
        <List data-id="referralCommentList">
          {sortedComments?.map((comment: Comment) => {
            const { icon, tooltipMessage } = getCommentIconAndToolTip(comment, referral);

            if (commentBeingEdited?.id === comment?.id) {
              return <EditComment comment={comment} templates={templates} />;
            }

            return (
              <Box
                key={comment.id}
                id={comment.id}
                style={{ background: comment.uploadStatus === UPLOAD_STATUSES.FAILED ? '#FEEBEE' : '' }}
              >
                <GenericCommentComponent
                  username={`${comment?.user?.firstName || ''} ${comment.user.lastName || ''}`}
                  timestamp={userFriendlyTimestamp(comment.createdAt, { persistTimezone: true })}
                  actionButtons={[
                    <IconButton
                      onClick={(e: any) => handleMoreIconClick(e, comment)}
                      disabled={!checkIfMoreIconShouldBeShown(comment, loggedInUserInfo)}
                      style={{
                        visibility: !checkIfMoreIconShouldBeShown(comment, loggedInUserInfo) ? 'hidden' : 'visible',
                      }}
                    >
                      <MoreVertIcon fontSize="large" style={{ color: '#0000008A', cursor: 'pointer' }} />
                    </IconButton>,
                    <Box
                      display="flex"
                      alignItems="center"
                      paddingRight={4}
                      style={{ visibility: hidePublishToEMR ? 'hidden' : 'visible' }}
                    >
                      <Tooltip placement="left" title={tooltipMessage}>
                        <img src={icon} alt="" style={{ cursor: 'default' }} />
                      </Tooltip>
                    </Box>,
                  ]}
                  headings={[
                    <Box style={{ paddingBottom: 8, paddingTop: 0 }} data-id="commentedTags">
                      {comment.referralFollowupTag && <FollowUpCommentActionChip chosen style={{ marginRight: 8 }} />}
                      {comment.patientContactTag && <PatientContactedCommentActionChip chosen />}
                    </Box>,
                  ]}
                >
                  <span dangerouslySetInnerHTML={{ __html: formatComment(comment.message) }} />
                </GenericCommentComponent>
              </Box>
            );
          })}
        </List>
      </Box>
    </Box>
  );
};

export default CommentsList;
