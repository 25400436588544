export const referralCommentTemplates = [
  {
    name: 'Copay Assistance Enrollment',
    content: `Enrolled in copay program:
Estimate per Vivid:
Card Number:
Exp date:
CVC:
Member ID:
Group#:
PCN:
BIN:
Vivid Est emailed to pt:`,
  },
  {
    name: 'FE Call',
    content: `Person spoke with:
OOP & Deductible from BIV discussed:
Estimate Amount: $        / visit
Frequency of infusion/injection:
Patient Agreed to discussed amount:
Copay enrollment needed:
Notified patient PA/PreD needed:
Clinical review/Clinicals still needed for PA:
Weekly update:
Input #/Email:
Phone & ext provided:
Additional information discussed with patient:
    `,
  },
  {
    name: 'Patient F/U',
    content: `Method:
#:
Spoke with:
F/U Provided:`,
  },
  {
    name: 'PA F/U',
    content: `Phone #:
Status:
Approval/Denial #:
Auth dates:
Confirm clinicals received if under review/pending:
Rep name:
Ref #:`,
  },
  {
    name: 'PA Submitted',
    content: `Insurance name:
Portal/ PA department:
Phone #:
Fax #:
Rep Name:
Ref #:
TAT:
Dosage requested:
Provider used/NPI #(make sure matches BIV):
BB or SP auth:
PA or PreD:`,
  },
  {
    name: 'Provider F/U',
    content: `Method:
#:
Spoke with:
F/U Provided:`,
  },
];
